// Generated by Framer (1249b65)

import { addFonts, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/wtt65xHP1";

const enabledGestures = {ZpUULMYcm: {hover: true}};

const cycleOrder = ["ZpUULMYcm"];

const variantClassNames = {ZpUULMYcm: "framer-v-1ik9n7t"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ZpUULMYcm", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-S8yH1", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1ik9n7t", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ZpUULMYcm"} ref={ref} style={{backgroundColor: "var(--token-4ea3ea55-8bec-4523-a2ad-576bc565cd98, rgb(76, 230, 166))", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}} {...addPropertyOverrides({"ZpUULMYcm-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1b8owhd"} layoutDependency={layoutDependency} layoutId={"dArkJoXO3"} style={{backgroundColor: "var(--token-e9918eff-958a-498e-b150-7017d5cc3030, rgb(5, 5, 5))", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, opacity: 0}} variants={{"ZpUULMYcm-hover": {backgroundColor: "var(--token-43aa6af2-0883-449e-b7d3-64c82acf81b1, rgb(26, 26, 26))", opacity: 1}}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1puyy8q"} data-styles-preset={"wtt65xHP1"}>Let's Connect!</motion.p></React.Fragment>} className={"framer-rw9z5v"} layoutDependency={layoutDependency} layoutId={"rSFkH6dPd"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} variants={{"ZpUULMYcm-hover": {"--extracted-r6o4lv": "var(--token-4ea3ea55-8bec-4523-a2ad-576bc565cd98, rgb(76, 230, 166))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"ZpUULMYcm-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-1puyy8q"} data-styles-preset={"wtt65xHP1"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-4ea3ea55-8bec-4523-a2ad-576bc565cd98, rgb(76, 230, 166)))"}}>Let's Connect!</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-S8yH1 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-S8yH1 .framer-d23b47 { display: block; }", ".framer-S8yH1 .framer-1ik9n7t { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 48px 48px 48px 48px; position: relative; width: 816px; will-change: var(--framer-will-change-override, transform); }", ".framer-S8yH1 .framer-1b8owhd { bottom: 48px; flex: none; left: 72px; overflow: hidden; position: absolute; right: 72px; top: 48px; will-change: var(--framer-will-change-override, transform); z-index: 0; }", ".framer-S8yH1 .framer-rw9z5v { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", ".framer-S8yH1 .framer-v-1ik9n7t .framer-1ik9n7t { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-S8yH1 .framer-1ik9n7t { gap: 0px; } .framer-S8yH1 .framer-1ik9n7t > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-S8yH1 .framer-1ik9n7t > :first-child { margin-left: 0px; } .framer-S8yH1 .framer-1ik9n7t > :last-child { margin-right: 0px; } }", ".framer-S8yH1.framer-v-1ik9n7t.hover .framer-1b8owhd { bottom: -48px; left: -48px; right: -48px; top: -48px; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 192
 * @framerIntrinsicWidth 816
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"KXw4BvtYm":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables false
 */
const FramerJOBJh89td: React.ComponentType<Props> = withCSS(Component, css, "framer-S8yH1") as typeof Component;
export default FramerJOBJh89td;

FramerJOBJh89td.displayName = "Connect Button";

FramerJOBJh89td.defaultProps = {height: 192, width: 816};

addFonts(FramerJOBJh89td, [...sharedStyle.fonts])